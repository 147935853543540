<template>
  <ZotloFooter
    v-if="section.type === 'zotloFooter'"
    class="tw-section"
    :section="section"
    :page-data="pageData"
    :page-options="pageOptions"
    :locale="locale"
  />
  <div
    v-else
    :id="section.id"
    class="tw-section"
    :style="(section.style as StyleValue)"
    :data-title="section.name"
    v-bind="section.attr"
  >
    <div class="tw-section-spacing">
      <div class="tw-section-container mx-auto my-0 flex flex-col lg:grid lg:grid-cols-12">
        <Column
          v-for="col in section.columns"
          :id="col.id"
          :key="col.id"
          :column="col"
          :class="getCurrentCol(col.colspan - 1)"
        >
          <slot
            v-bind="
              filterElementsByConditionalRefs(col.elements, pageData.hiddenConditionalRefs)
                .filter((el) => el.options.visible)"
          />
        </Column>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type PropType, ref, type StyleValue, computed, toRef } from 'vue';
import type { Section, PageOptions } from '@shared/types/model';
import { filterElementsByConditionalRefs } from '@shared/utils';
import { useSpacing } from '@shared/composable/useSpacing';
import Column from './column.vue';
import ZotloFooter from './zotlo-footer.vue';

const props = defineProps({
  section: { type: Object as PropType<Section>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' },
});

const section = toRef(props, 'section');
const { padding, margin } = useSpacing(section);

const colList = ref([
  'md:col-span-1',
  'md:col-span-2',
  'md:col-span-3',
  'md:col-span-4',
  'md:col-span-5',
  'md:col-span-6',
  'md:col-span-7',
  'md:col-span-8',
  'md:col-span-9',
  'md:col-span-10',
  'md:col-span-11',
  'md:col-span-12'
]);

const backgroundImage = computed(() => {
  const forceDesktopBG = props.section.options.forceDesktopBG;
  const { desktop, tablet, mobile } = props.section.options?.background || {};

  return {
    desktop: desktop || '',
    tablet: (forceDesktopBG ? desktop : tablet) || '',
    mobile: (forceDesktopBG ? desktop : mobile) || '',
  }
});

function getCurrentCol(index: number) {
  return colList.value[index];
}
</script>

<style lang="postcss" scoped>
  .tw-section {
    --section-desktop-backgroundImage: v-bind('backgroundImage.desktop');
    --section-tablet-backgroundImage: v-bind('backgroundImage.tablet');
    --section-mobile-backgroundImage: v-bind('backgroundImage.mobile');

    /* PADDING */
    --section-desktop-padding-top: v-bind('padding?.desktop?.top');
    --section-desktop-padding-left: v-bind('padding?.desktop?.left');
    --section-desktop-padding-bottom: v-bind('padding?.desktop?.bottom');
    --section-desktop-padding-right: v-bind('padding?.desktop?.right');

    --section-tablet-padding-top: v-bind('padding?.tablet?.top');
    --section-tablet-padding-left: v-bind('padding?.tablet?.left');
    --section-tablet-padding-bottom: v-bind('padding?.tablet?.bottom');
    --section-tablet-padding-right: v-bind('padding?.tablet?.right');

    --section-mobile-padding-top: v-bind('padding?.mobile?.top');
    --section-mobile-padding-left: v-bind('padding?.mobile?.left');
    --section-mobile-padding-bottom: v-bind('padding?.mobile?.bottom');
    --section-mobile-padding-right: v-bind('padding?.mobile?.right');
    
    /* MARGIN */
    --section-desktop-margin-top: v-bind('margin?.desktop?.top');
    --section-desktop-margin-left: v-bind('margin?.desktop?.left');
    --section-desktop-margin-bottom: v-bind('margin?.desktop?.bottom');
    --section-desktop-margin-right: v-bind('margin?.desktop?.right');

    --section-tablet-margin-top: v-bind('margin?.tablet?.top');
    --section-tablet-margin-left: v-bind('margin?.tablet?.left');
    --section-tablet-margin-bottom: v-bind('margin?.tablet?.bottom');
    --section-tablet-margin-right: v-bind('margin?.tablet?.right');

    --section-mobile-margin-top: v-bind('margin?.mobile?.top');
    --section-mobile-margin-left: v-bind('margin?.mobile?.left');
    --section-mobile-margin-bottom: v-bind('margin?.mobile?.bottom');
    --section-mobile-margin-right: v-bind('margin?.mobile?.right');

    position: relative;
    background-image: var(--section-mobile-backgroundImage);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    margin-top: var(--section-mobile-margin-top, 0);
    margin-left: var(--section-mobile-margin-left, 0);
    margin-bottom: var(--section-mobile-margin-bottom, 0);
    margin-right: var(--section-mobile-margin-right, 0);

    &-spacing {
      padding-top: var(--section-mobile-padding-top, 0);
      padding-left: var(--section-mobile-padding-left, 0);
      padding-bottom: var(--section-mobile-padding-bottom, 0);
      padding-right: var(--section-mobile-padding-right, 0);
    }

    &-container{
      max-width: 1320px;
      margin: 0 auto;
    }

    &.flex-container &-container {
      max-width: none;
    }
  }

  .desktop {
    .tw-section {
      background-image: var(--section-desktop-backgroundImage);
      margin-top: var(--section-desktop-margin-top, 0);
      margin-left: var(--section-desktop-margin-left, 0);
      margin-bottom: var(--section-desktop-margin-bottom, 0);
      margin-right: var(--section-desktop-margin-right, 0);

      &-spacing {
        padding-top: var(--section-desktop-padding-top, 0);
        padding-left: var(--section-desktop-padding-left, 0);
        padding-bottom: var(--section-desktop-padding-bottom, 0);
        padding-right: var(--section-desktop-padding-right, 0);
      }
    }
  }

  .tablet {
    .tw-section {
      background-image: var(--section-tablet-backgroundImage);
      margin-top: var(--section-tablet-margin-top, 0);
      margin-left: var(--section-tablet-margin-left, 0);
      margin-bottom: var(--section-tablet-margin-bottom, 0);
      margin-right: var(--section-tablet-margin-right, 0);

      &-spacing {
        padding-top: var(--section-tablet-padding-top, 0);
        padding-left: var(--section-tablet-padding-left, 0);
        padding-bottom: var(--section-tablet-padding-bottom, 0);
        padding-right: var(--section-tablet-padding-right, 0);
      }
    }
  }

  .mobile {
    .tw-section {
      background-image: var(--section-mobile-backgroundImage);
      margin-top: var(--section-mobile-margin-top, 0);
      margin-left: var(--section-mobile-margin-left, 0);
      margin-bottom: var(--section-mobile-margin-bottom, 0);
      margin-right: var(--section-mobile-margin-right, 0);

      &-spacing {
        padding-top: var(--section-mobile-padding-top, 0);
        padding-left: var(--section-mobile-padding-left, 0);
        padding-bottom: var(--section-mobile-padding-bottom, 0);
        padding-right: var(--section-mobile-padding-right, 0);
      }
    }
  }

  @media (min-width: 768px) {
    .tw-section {
      background-image: var(--section-tablet-backgroundImage);
      margin-top: var(--section-tablet-margin-top, 0);
      margin-left: var(--section-tablet-margin-left, 0);
      margin-bottom: var(--section-tablet-margin-bottom, 0);
      margin-right: var(--section-tablet-margin-right, 0);

      &-spacing {
        padding-top: var(--section-tablet-padding-top, 0);
        padding-left: var(--section-tablet-padding-left, 0);
        padding-bottom: var(--section-tablet-padding-bottom, 0);
        padding-right: var(--section-tablet-padding-right, 0);
      }
    }
  }

  @media (min-width: 1024px) {
    .tw-section {
      background-image: var(--section-desktop-backgroundImage);
      margin-top: var(--section-desktop-margin-top, 0);
      margin-left: var(--section-desktop-margin-left, 0);
      margin-bottom: var(--section-desktop-margin-bottom, 0);
      margin-right: var(--section-desktop-margin-right, 0);

      &-spacing {
        padding-top: var(--section-desktop-padding-top, 0);
        padding-left: var(--section-desktop-padding-left, 0);
        padding-bottom: var(--section-desktop-padding-bottom, 0);
        padding-right: var(--section-desktop-padding-right, 0);
      }
    }
  }
</style>
